<template lang="">
    <div class="p-5" >
        <b-card no-body :header=" $t('HomePage.law') ">
    <b-list-group flush>
      <b-list-group-item v-for="(item,index) in items" :href="item.link" _blank  :key="item.id"  >
        <b-avatar :text="index+1"></b-avatar>  {{item.title}}
      </b-list-group-item>
 
    </b-list-group>
 
  </b-card>
    </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      items: []
    }
  },
  created() {
    axios
      .get(
        this.$store.getters.getBaseUrl + "/law/1/1", {
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
          "Accept-Encoding": "gzip, deflate, br;q=1.0, identity;q=0.5, *;q=0.25"
        }
      }
      )
      .then((response) => {
        // JSON responses are automatically parsed.
        this.items = response.data

      })
  },

}
</script>
<style lang="">
    
</style>